import React, { useState } from 'react';
import './Inscripcion.css';
import emailjs from 'emailjs-com'; // Importamos EmailJS

const Inscripcion = () => {
  const [formData, setFormData] = useState({
    team_manager: '',
    pilotos_adicionales: '1',
    nombre_equipo: '',
    ha_participado: 'NO',
    provincia: '',
    email: '',
    telefono: '',
    comentarios: '' // Campo para comentarios
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [charCount, setCharCount] = useState(0); // Contador de caracteres

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Si es el campo de comentarios, actualiza el contador de caracteres
    if (name === 'comentarios') {
      setCharCount(value.length);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Reemplaza 'YOUR_SERVICE_ID' y 'YOUR_USER_ID' con los valores obtenidos en EmailJS
    const serviceID = 'service_ynterxg';  // Reemplaza con tu Service ID de EmailJS
    const publicKey = 'ARnQ6WDqroOwCHJpq'; // Reemplaza con tu Public Key de EmailJS

    // Enviar el correo a ti (sin respuesta automática al usuario)
    emailjs.sendForm(
      serviceID, 
      'template_kvmzvq9', // Reemplaza con el ID de la plantilla para enviarte el formulario
      e.target, 
      publicKey
    ).then((result) => {
      console.log('Correo enviado a ti:', result.text);
      setFormSubmitted(true); // Cambiar el estado para mostrar mensaje de éxito
    }, (error) => {
      console.log('Error al enviar el correo a ti:', error.text);
    });
    
    e.target.reset(); // Resetea el formulario después de enviarlo
    setCharCount(0); // Resetea el contador de caracteres
  };

  return (
    <section id="inscripcion" className="inscripcion-section">
      <h2><span className="pre-inscripcion">(Pre) - </span>Inscripción</h2>

      {formSubmitted ? (
        <p>¡Gracias por tu inscripción! Nos pondremos en contacto contigo muy pronto.</p>
      ) : (
        <form className="inscripcion-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="team_manager">Nombre y Apellidos:</label>
            <input
              type="text"
              id="team_manager"
              name="team_manager"
              value={formData.team_manager}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="pilotos_adicionales">Número de pilotos:</label>
            <select
              id="pilotos_adicionales"
              name="pilotos_adicionales"
              value={formData.pilotos_adicionales}
              onChange={handleChange}
              required
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="nombre_equipo">Nombre del equipo:</label>
            <input
              type="text"
              id="nombre_equipo"
              name="nombre_equipo"
              value={formData.nombre_equipo}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="ha_participado">¿Has participado con nosotros previamente?</label>
            <select
              id="ha_participado"
              name="ha_participado"
              value={formData.ha_participado}
              onChange={handleChange}
              required
            >
              <option value="SI">Sí</option>
              <option value="NO">No</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="provincia">Provincia:</label>
            <select
              id="provincia"
              name="provincia"
              value={formData.provincia}
              onChange={handleChange}
              required
            >
              <option value="">Selecciona tu provincia</option>
              <option value="Álava">Álava</option>
              <option value="Albacete">Albacete</option>
              <option value="Alicante">Alicante</option>
              <option value="Almería">Almería</option>
              <option value="Asturias">Asturias</option>
              <option value="Ávila">Ávila</option>
              <option value="Badajoz">Badajoz</option>
              <option value="Barcelona">Barcelona</option>
              <option value="Burgos">Burgos</option>
              <option value="Cáceres">Cáceres</option>
              <option value="Cádiz">Cádiz</option>
              <option value="Cantabria">Cantabria</option>
              <option value="Castellón">Castellón</option>
              <option value="Ciudad Real">Ciudad Real</option>
              <option value="Córdoba">Córdoba</option>
              <option value="Cuenca">Cuenca</option>
              <option value="Girona">Girona</option>
              <option value="Granada">Granada</option>
              <option value="Guadalajara">Guadalajara</option>
              <option value="Guipúzcoa">Guipúzcoa</option>
              <option value="Huelva">Huelva</option>
              <option value="Huesca">Huesca</option>
              <option value="Illes Balears">Illes Balears</option>
              <option value="Jaén">Jaén</option>
              <option value="La Coruña">La Coruña</option>
              <option value="La Rioja">La Rioja</option>
              <option value="Las Palmas">Las Palmas</option>
              <option value="León">León</option>
              <option value="Lleida">Lleida</option>
              <option value="Lugo">Lugo</option>
              <option value="Madrid">Madrid</option>
              <option value="Málaga">Málaga</option>
              <option value="Murcia">Murcia</option>
              <option value="Navarra">Navarra</option>
              <option value="Ourense">Ourense</option>
              <option value="Palencia">Palencia</option>
              <option value="Pontevedra">Pontevedra</option>
              <option value="Salamanca">Salamanca</option>
              <option value="Santa Cruz de Tenerife">Santa Cruz de Tenerife</option>
              <option value="Segovia">Segovia</option>
              <option value="Sevilla">Sevilla</option>
              <option value="Soria">Soria</option>
              <option value="Tarragona">Tarragona</option>
              <option value="Teruel">Teruel</option>
              <option value="Toledo">Toledo</option>
              <option value="Valencia">Valencia</option>
              <option value="Valladolid">Valladolid</option>
              <option value="Vizcaya">Vizcaya</option>
              <option value="Zamora">Zamora</option>
              <option value="Zaragoza">Zaragoza</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="email">Correo Electrónico:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="telefono">Teléfono de contacto:</label>
            <input
              type="tel"
              id="telefono"
              name="telefono"
              value={formData.telefono}
              onChange={handleChange}
              required
            />
          </div>

          {/* Nuevo campo de comentarios */}
          <div className="form-group">
            <label htmlFor="comentarios">Comentarios y sugerencias (máx. 600 caracteres):</label>
            <textarea
              id="comentarios"
              name="comentarios"
              value={formData.comentarios}
              onChange={handleChange}
              maxLength="600"
              rows="5"
              placeholder="Escribe aquí tus comentarios..."
            ></textarea>
            <p>{charCount}/600 caracteres</p>
          </div>

          <button type="submit" className="submit-button">Enviar</button>
        </form>
      )}

      {/* Enlace a la política de protección de datos */}
      <p className="policy-text">
        * Al enviar este formulario, aceptas nuestra <a href="https://www.tandasprivadas.com/politica-privacidad/" target="_blank" rel="noopener noreferrer">Política de Protección de Datos</a>.
      </p>
    </section>
  );
};

export default Inscripcion;
