import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <section className="hero">
      <div 
        className="background-image" 
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/honda-civic.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'scroll',
        }} 
      />
      <div className="hero-text">
      <h1>CAMPEONATO</h1>
        <h1>500KM</h1>
               
        <p>
          Descubre el "Campeonato500Km". Evento creado para los amantes del motor y la adrenalina,
          donde pondrás a prueba tu vehículo y tu estrategía.
        </p>
        <p>
          ¿Necesitas un entrenamiento para tus pruebas federadas? Este es tu sitio.
        </p>
        <p>
           Prepárate para vivir, junto a tu equipo, la emoción y la pasión de los 500Km como nunca antes.
        </p>
        
        {/* Frase de Michael Schumacher */}
        <div className="schumacher-quote">
          <p className="quote">
            "Si piensas que has llegado a tu límite, es cuando tu verdadero reto comienza."
          </p>
          <p className="signature">Michael Schumacher</p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
