import React from 'react';
import './Circuitos.css'; // Importamos los estilos de la sección Circuitos

const Circuitos = () => {
  const circuitosData = [
    {
      name: 'Circuito de Kotarr',
      location: 'Tubilla del Lago, Burgos',
      description: 'El Circuito de Kotarr también conocido como Circuito de Velocidad Kotarr es un circuito de velocidad tipo corto que se encuentra en Tubilla del Lago. Es el primer circuito de velocidad de toda Castilla y León y fue inaugurado en agosto de 2008.',
      length: '2,250 km',
      image: `${process.env.PUBLIC_URL}/images/Trazado-KOTARR.jpg`, // Actualizamos la ruta
      mapLink: 'https://maps.app.goo.gl/zep767Gh61ThgvJQzE6',
    },
    {
      name: 'Circuito FK1',
      location: 'Villaverde de Medina, Valladolid',
      description: 'El Circuito FK1 está situado en Villaverde de Medina, Valladolid. Inaugurado en 2007, se ha consolidado como un referente para competiciones de automovilismo y motociclismo. Su reciente modificación en el trazado ha añadido mayor dinamismo y versatilidad al circuito, permitiendo varias configuraciones adaptadas a diferentes tipos de eventos. Con un recorrido técnico que combina curvas exigentes y rectas rápidas, FK1 ofrece un desafío único para los pilotos.',
      length: '2,950 km',
      image: `${process.env.PUBLIC_URL}/images/Fk1.jpg`, // Actualizamos la ruta
      mapLink: 'https://maps.app.goo.gl/ASFfR9QpLnihPu3g7',
    },
    {
      name: 'Circuito de Calafat',
      location: 'La Ametlla de Mar, Tarragona',
      description: 'El Circuito de Calafat está situado en Ametlla de Mar, Tarragona. Fue inaugurado en 1974 y es uno de los primeros circuitos permanentes construidos en España. Ha albergado diversas competiciones de automovilismo y motociclismo a lo largo de su historia.',
      length: '3,250 km',
      image: `${process.env.PUBLIC_URL}/images/Calafat.jpg`, // Actualizamos la ruta
      mapLink: 'https://maps.app.goo.gl/6tGaPtWs4F6eMmYVA',
    },
    
    
    
  ];

  return (
    <section id="circuitos" className="circuitos-section">
      <h2>Circuitos</h2>
      <div className="circuitos-list">
        {circuitosData.map((circuito, index) => (
          <div className="circuito-item" key={index}>
            <img src={circuito.image} alt={`Imagen de ${circuito.name}`} className="circuito-image" />
            <div className="circuito-info">
              <h3>{circuito.name}</h3>
              <p><strong>Ubicación:</strong> {circuito.location}</p>
              <p><strong>Longitud:</strong> {circuito.length}</p>
              <p>{circuito.description}</p>
              {circuito.mapLink && (
                <p>
                  <a href={circuito.mapLink} target="_blank" rel="noopener noreferrer">
                    Ver en Google Maps
                  </a>
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Circuitos;
