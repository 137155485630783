import React from 'react';
import './Informacion.css'; // Importamos los estilos de la sección

const Informacion = () => {
  return (
    <section id="informacion" className="informacion-section">
      <h2>Información del Campeonato</h2>
      <div className="informacion-gallery">
        {/* Añadimos 3 imágenes en lugar del texto */}
        <img src={`${process.env.PUBLIC_URL}/images/500-1.jpg`} alt="Foto 1 del Campeonato" />
        <img src={`${process.env.PUBLIC_URL}/images/500-2.jpg`} alt="Foto 2 del Campeonato" />
        <img src={`${process.env.PUBLIC_URL}/images/500-3.jpg`} alt="Foto 3 del Campeonato" />
      </div>

      <p>Estamos trabajando para ofrecerte la mejor experiencia, sin perder la esencia de los 500KM. Rellena nuestro formulario y sé el primero en enterarte de las novedades.</p><p>Muy pronto, más información.</p>
    </section>
  );
};

export default Informacion;
